import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate,
  useParams,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import type { LoaderFunction } from "@remix-run/node";
import "./tailwind.css";

// Import rootAuthLoader
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import {
  ClerkApp,
  SignedIn,
  SignedOut,
  SignInButton,
  SignUpButton,
} from "@clerk/remix";
import { Button } from "./components/shadcn/button";
import config from "./utils/shadcn/lib/config";
import { useEffect } from "react";

// Export as the root route loader
export const loader: LoaderFunction = (args) =>
  rootAuthLoader(args, ({ request }) => {
    const { userId } = request.auth;
    // fetch data
    return {
      userId,
      ENV: {
        LOANX_APP_URL_BASE: config.appUrlBase,
      },
    };
  });

export const meta: MetaFunction = () => {
  return [
    { title: "LoanX.ai App" },
    {
      property: "og:title",
      content: "LoanX.ai App",
    },
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1",
    },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { ENV } = useRouteLoaderData<typeof loader>("root") || {};

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;800&display=swap"
          rel="stylesheet"
        />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

function SignedOutPage() {
  return (
    <div className="h-screen bg-grey-400 flex items-center justify-center">
      <div className="p-4">
        <SignInButton>
          <Button>Sign In</Button>
        </SignInButton>
      </div>
      <div className="p-4">
        <SignUpButton>
          <Button>Sign Up</Button>
        </SignUpButton>
      </div>
    </div>
  );
}

function App() {
  const navigate = useNavigate();
  const params = useParams();

  const hasParams = Object.keys(params || {}).length > 0;

  useEffect(() => {
    if (!hasParams) {
      navigate("/loans");
    }
  }, []);

  return (
    <div className="flex-col flex">
      <SignedIn>
        <Outlet />
      </SignedIn>
      <SignedOut>
        <SignedOutPage />
      </SignedOut>
    </div>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong: {JSON.stringify(error, null, 2)}</div>;
};

export default ClerkApp(App, {
  appearance: {
    layout: {
      unsafe_disableDevelopmentModeWarnings: true,
    },
    variables: {
      // colorPrimary: "#3B82F6",
      // colorText: "white",
      borderRadius: "0.2rem",
    },
  },
});
